@import 'src/assets/styles/common';

.video-container {
  position: relative;
}

.video-container-el {
  @include size(100%);
  display: block;
}

.video-container-loader,
.video-container-overlay {
  @include flex(center, center);
  @include size(100%);
  position: absolute;

  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.2);

  opacity: 0;
  will-change: opacity;
  transition: opacity 200ms ease-in-out 15ms;
}

.video-container.__show .video-container-overlay {
  opacity: 1;
}

.video-container-loader {
}
.video-container.__load .video-container-loader {
  opacity: 1;
}

.video-container-button {
  @include flex(center, center);
  @include size(56px);

  border-radius: 32px;
  border: none;
  padding: 0;
  margin: 0;

  background: $blue-6;

  cursor: pointer;

  svg {
    @include size(24px);
    display: block;
    position: relative;
    right: -2px;
  }
}

.video-container-loader-el {
  display: block;
  width: 300px;
}
